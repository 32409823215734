import React from 'react';

function About() {
  return (
    <div className="container mt-5">
      <h1>About Us</h1>
      <p>This is the about page of our website, where you can learn more about us.</p>
    </div>
  );
}

export default About;
