import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

function FloatingMenu() {
  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowTopButton(window.scrollY > 100); // 100px以上スクロールで表示
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    showTopButton && (
      <div className="top-button">
        <Link to="top" smooth={true} duration={500} className="menu-link">
          TOP
        </Link>
        <br />
        <Link to="contact" smooth={true} duration={500} className="menu-link">
          Contact
        </Link>
      </div>
    )
  );
}

export default FloatingMenu;
