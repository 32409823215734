import React from 'react';

function Footer() {
  return (
    <div style={{ padding: '20px 0 0' }}>

      <div id="footer-data" className="container p-4">
        <div className="row">
          <div className="col-12 col-md-6 text-center">
	    <img src={`${process.env.PUBLIC_URL}/images/title_logo320.png`} alt="Title Logo" width="200px" />
          </div>
          <div className="col-12 col-md-6">

	    <table border="1" cellPadding="10" cellSpacing="0">
	      <tbody>
		<tr>
		  <td colSpan="2">株式会社pixys (ピクシス）</td>
		</tr>
		<tr>
		  <td>浦添office</td>
		  <td>〒901-2114 <div className="sp-only"><br/></div>沖縄県浦添市安波茶1-6-5</td>
		</tr>
		<tr>
		  <td>八重山office</td>
		  <td>〒907-0024 <div className="sp-only"><br/></div>沖縄県石垣市新川280-2 1F</td>
		</tr>
		<tr>
		  <td>電話</td>
		  <td>0980-87-0806 <div className="sp-only"><br/></div>(平日:10:00~17:00)</td>
		</tr>
		<tr>
		  <td>メールアドレス</td>
		  <td>info@pixys.co.jp</td>
		</tr>
		<tr>
		  <td colSpan="2"><a href="/policy">プライバシーポリシー</a></td>
		</tr>
		<tr>
		  <td colSpan="2"><a href="/terms">利用規約</a></td>
		</tr>
	      </tbody>
	    </table>
	    
          </div>
        </div>
      </div>



      <footer className="bg-light text-center text-lg-start">
	<div className="container p-4">
          <p>(c) 2024 Pixys Co.,Ltd. All rights reserved.</p>
	</div>
      </footer>
    </div>
  );
}

export default Footer;
