import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import Policy from './components/Policy';
import Terms from './components/Terms';
import ModelView from './components/ModelView';
import FloatingMenu from './components/FloatingMenu'; 
import ContactForm from './components/ContactForm';
import './App.css';

function App() {
  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowTopButton(window.scrollY > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
      <Router>
	<div id="top"></div>
	<Header />
	<Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/modelview" element={<ModelView />} />
	</Routes>
	<FloatingMenu />
	<Footer />
      </Router>
  );
}
export default App;
