import React, { useRef, useEffect, Suspense, useState, useMemo } from 'react';
import { Canvas, useFrame, useThree, extend } from '@react-three/fiber';
import { useGLTF, useProgress } from '@react-three/drei';
import * as THREE from 'three';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils';

// 必要なThree.jsクラスをextendで登録
extend({ Scene: THREE.Scene, Group: THREE.Group, Mesh: THREE.Mesh, Object3D: THREE.Object3D });

function Model() {
  const { scene } = useGLTF('/models/ishigakijimamaturi_stage_image.glb');
  const copiedScene = useMemo(() => clone(scene), [scene]);
  return <primitive object={copiedScene} />;
}

function LoadingSpinner() {
  return (
    <div className="modelchild">
      <div className="spinner"></div>
      <p>Loading...</p>
    </div>
  );
}

function CameraControl({ setResetCameraFunc }) {
  //  const { gl } = useThree();
  const { gl, camera } = useThree();


  // マウスとタッチの状態を追跡するための変数
  const isMouseDown = useRef(false);
  const isRightMouseDown = useRef(false); // 右ボタンの状態を追加
  const lastMousePosition = useRef({ x: 0, y: 0 });

  const isTouching = useRef(false);
  const lastTouchPositions = useRef([]);
  const lastTouchDistance = useRef(0);

  // カメラの移動速度を管理するための変数
  const velocity = useRef(0);
  const panOffset = useRef(new THREE.Vector3());
  
  useEffect(() => {
    const resetCamera = () => {
      camera.position.set(0, 0, 5);
      camera.rotation.set(0, 0, 0);
    };
    setResetCameraFunc(() => resetCamera);
    
    // コンテキストメニューの表示を防止
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const handleMouseDown = (event) => {
      if (event.button === 0) {
        // 左ボタン
        isMouseDown.current = true;
        lastMousePosition.current = { x: event.clientX, y: event.clientY };
      } else if (event.button === 2) {
        // 右ボタン
        isRightMouseDown.current = true;
        lastMousePosition.current = { x: event.clientX, y: event.clientY };
      }
    };

    const handleMouseUp = (event) => {
      if (event.button === 0) {
        isMouseDown.current = false;
      } else if (event.button === 2) {
        isRightMouseDown.current = false;
      }
    };

    const handleMouseMove = (event) => {
      if (isMouseDown.current && camera) {
        // 左ボタンでの回転
        const deltaX = event.clientX - lastMousePosition.current.x;
        const deltaY = event.clientY - lastMousePosition.current.y;

        const sensitivity = 0.005;

        camera.rotation.y -= deltaX * sensitivity;
        camera.rotation.x -= deltaY * sensitivity;

        camera.rotation.x = Math.max(
          -Math.PI / 2,
          Math.min(Math.PI / 2, camera.rotation.x)
        );

        lastMousePosition.current = { x: event.clientX, y: event.clientY };
      } else if (isRightMouseDown.current && camera) {
        // 右ボタンでのパン
        const deltaX = event.clientX - lastMousePosition.current.x;
        const deltaY = event.clientY - lastMousePosition.current.y;

        const panSensitivity = 0.005;

        // カメラの右方向と上方向のベクトルを取得
//        const camera = cameraRef.current;
        const right = new THREE.Vector3();
        const up = new THREE.Vector3();
        camera.getWorldDirection(right);
        right.cross(camera.up).normalize(); // カメラの右方向
        up.copy(camera.up).normalize(); // カメラの上方向

        // パンオフセットを計算
        right.multiplyScalar(-deltaX * panSensitivity);
        up.multiplyScalar(deltaY * panSensitivity);

        panOffset.current.add(right).add(up);

        lastMousePosition.current = { x: event.clientX, y: event.clientY };
      }
    };

    // タッチイベントのハンドラ
    const handleTouchStart = (event) => {
      event.preventDefault();
      isTouching.current = true;

      if (event.touches.length === 1) {
        // シングルタッチの開始位置を記録
        const touch = event.touches[0];
        lastTouchPositions.current = [{ x: touch.clientX, y: touch.clientY }];
      } else if (event.touches.length === 2) {
        // 2本指の開始位置を記録
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];

        lastTouchPositions.current = [
          { x: touch1.clientX, y: touch1.clientY },
          { x: touch2.clientX, y: touch2.clientY },
        ];

        // ピンチズームのための初期距離を計算
        lastTouchDistance.current = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );
      }
    };

    const handleTouchMove = (event) => {
      event.preventDefault();
      if (!isTouching.current || !camera) return;

      if (event.touches.length === 1) {
        // シングルタッチでの回転
        const touch = event.touches[0];
        const deltaX = touch.clientX - lastTouchPositions.current[0].x;
        const deltaY = touch.clientY - lastTouchPositions.current[0].y;

        const sensitivity = 0.005;

        camera.rotation.y -= deltaX * sensitivity;
        camera.rotation.x -= deltaY * sensitivity;

        camera.rotation.x = Math.max(
          -Math.PI / 2,
          Math.min(Math.PI / 2, camera.rotation.x)
        );

        lastTouchPositions.current = [{ x: touch.clientX, y: touch.clientY }];
      } else if (event.touches.length === 2) {
        // 2本指でのピンチズームとパン操作
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];

        // 現在の2本指の位置
        const currentPositions = [
          { x: touch1.clientX, y: touch1.clientY },
          { x: touch2.clientX, y: touch2.clientY },
        ];

        // ピンチズームの処理
        const currentDistance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );
        const deltaDistance = currentDistance - lastTouchDistance.current;
        const zoomSensitivity = 0.005;
        velocity.current += deltaDistance * zoomSensitivity;
        lastTouchDistance.current = currentDistance;

        // パン操作の処理
        const lastMidpoint = {
          x: (lastTouchPositions.current[0].x + lastTouchPositions.current[1].x) / 2,
          y: (lastTouchPositions.current[0].y + lastTouchPositions.current[1].y) / 2,
        };
        const currentMidpoint = {
          x: (currentPositions[0].x + currentPositions[1].x) / 2,
          y: (currentPositions[0].y + currentPositions[1].y) / 2,
        };

        const deltaX = currentMidpoint.x - lastMidpoint.x;
        const deltaY = currentMidpoint.y - lastMidpoint.y;

        const panSensitivity = 0.001;

        // カメラの右方向と上方向のベクトルを取得
//        const camera = cameraRef.current;
        const right = new THREE.Vector3();
        const up = new THREE.Vector3();
        camera.getWorldDirection(right);
        right.cross(camera.up).normalize(); // カメラの右方向
        up.copy(camera.up).normalize(); // カメラの上方向

        // パンオフセットを計算
        right.multiplyScalar(-deltaX * panSensitivity);
        up.multiplyScalar(deltaY * panSensitivity);

        panOffset.current.add(right).add(up);

        lastTouchPositions.current = currentPositions;
      }
    };

    const handleTouchEnd = (event) => {
      event.preventDefault();
      if (event.touches.length === 0) {
        isTouching.current = false;
      } else if (event.touches.length === 1) {
        // 2本指から1本指になった場合、シングルタッチの位置をリセット
        const touch = event.touches[0];
        lastTouchPositions.current = [{ x: touch.clientX, y: touch.clientY }];
      }
    };

    const handleWheel = (event) => {
      event.preventDefault(); // デフォルトのスクロール動作を防止
      if (camera) {
        // ホイールのスクロール量を累積して速度に反映
        const delta = -event.deltaY * 0.01; // ホイールのスクロール方向を調整

        // 速度を更新（累積）
        velocity.current += delta;
      }
    };

    // イベントリスナーを追加
    gl.domElement.addEventListener('mousedown', handleMouseDown);
    gl.domElement.addEventListener('mouseup', handleMouseUp);
    gl.domElement.addEventListener('mousemove', handleMouseMove);
    gl.domElement.addEventListener('wheel', handleWheel, { passive: false });

    gl.domElement.addEventListener('touchstart', handleTouchStart, { passive: false });
    gl.domElement.addEventListener('touchmove', handleTouchMove, { passive: false });
    gl.domElement.addEventListener('touchend', handleTouchEnd);

    // クリーンアップ
    return () => {
      gl.domElement.removeEventListener('mousedown', handleMouseDown);
      gl.domElement.removeEventListener('mouseup', handleMouseUp);
      gl.domElement.removeEventListener('mousemove', handleMouseMove);
      gl.domElement.removeEventListener('wheel', handleWheel);

      gl.domElement.removeEventListener('touchstart', handleTouchStart);
      gl.domElement.removeEventListener('touchmove', handleTouchMove);
      gl.domElement.removeEventListener('touchend', handleTouchEnd);
    };
  }, [camera, gl, setResetCameraFunc]);

  // useFrameで毎フレームごとにカメラを移動し、速度を減衰させる
  useFrame(() => {
    if (camera) {
//      const camera = camera;

      // カメラの前方向ベクトルを取得
      const direction = new THREE.Vector3();
      camera.getWorldDirection(direction);

      // カメラの位置を更新（前後に移動）
      camera.position.addScaledVector(direction, velocity.current);

      // パンオフセットを適用
      camera.position.add(panOffset.current);
      // パンオフセットを減衰させる
      panOffset.current.multiplyScalar(0.8);

      // 速度を減衰させる
      velocity.current *= 0.8;

      // 最小速度の閾値を設定して停止させる
      if (Math.abs(velocity.current) < 0.0001) {
        velocity.current = 0;
      }
      if (panOffset.current.length() < 0.0001) {
        panOffset.current.set(0, 0, 0);
      }
    }
  });
  
  //  return <PerspectiveCamera ref={cameraRef} makeDefault position={[0, 0, 5]} />;
  return null; // JSXを返さない
}

export default function ModelView(){
//  const cameraRef = useRef();
  const [resetCameraFunc, setResetCameraFunc] = useState(null);
  // モデルの読み込み進捗を取得
  const { progress } = useProgress();
  // モデルが読み込み中かどうかを判定
  const isLoading = progress < 100;
  
  return (
    <div className="container mt-5">

      <h1>3Dビューの会場地図のサンプル</h1>

      
      <div style={{ position: 'relative' }}>
      
        {/* 読み込み中はLoadingSpinnerを表示 */}
        {isLoading && <LoadingSpinner />}

	<Canvas id="canvas">
          {/* カメラコントロール */}
          <CameraControl setResetCameraFunc={setResetCameraFunc} />
	  
	  {/* ライトを追加 */}
	  <ambientLight intensity={0.5} />
	  <directionalLight position={[0, 5, 5]} />
	  {/* Suspenseでロード中の処理 */}
	  <Suspense fallback={null}>
            <Model />
	  </Suspense>
	</Canvas>
      </div>
      <div className="p-1"></div>
      <button
	 type="button"
	 className="btn btn-primary btn-lg ib"
    //	 onClick={resetCamera}
    onClick={() => resetCameraFunc && resetCameraFunc()}
	 >
	カメラリセット
      </button>
      {/* PCで表示するコンテンツ */}
      <div className="d-none d-md-block p-2">
	画面内をマウスで操作できます<br/>
	<table border="1">
	  <thead>
	    <tr>
	      <th>操作</th>
	      <th>PC (マウス操作)</th>
	    </tr>
	  </thead>
	  <tbody>
	    <tr>
	      <td>カメラ回転</td>
	      <td>左クリック（`mousedown`）後、マウス移動でカメラ回転</td>
	    </tr>
	    <tr>
	      <td>カメラパン</td>
	      <td>右クリック（`mousedown`）後、マウス移動でカメラのパン操作</td>
	    </tr>
	    <tr>
	      <td>ズーム</td>
	      <td>ホイールスクロール（`wheel`）でカメラズームイン/アウト</td>
	    </tr>
	  </tbody>
	</table>
      </div>
      
      {/* スマホ（SP）で表示するコンテンツ */}
      <div className="d-block d-md-none p-2">
	<table border="1">
	  <thead>
	    <tr>
	      <th>操作</th>
	      <th>SP (タッチ操作)</th>
	    </tr>
	  </thead>
	  <tbody>
	    <tr>
	      <td>カメラ回転</td>
	      <td>シングルタッチでスワイプ</td>
	    </tr>
	    <tr>
	      <td>カメラパン</td>
	      <td>2本指のタッチ移動</td>
	    </tr>
	    <tr>
	      <td>ズーム</td>
	      <td>2本指のピンチイン・ピンチアウトでズーム</td>
	    </tr>
	  </tbody>
	</table>
      </div>
    </div>
  );
}
