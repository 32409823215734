import React from 'react';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation(); // 現在のURLパスを取得
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand" href="/"><img src="/images/title.png" height="32px"/></a>
          <div className="collapse navbar-collapse">
	    {location.pathname === "/" && (  // URLが"/"のときのみ表示
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="top" smooth={true} duration={600}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="aboutus" smooth={true} duration={600}>About</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="contact" smooth={true} duration={600}>Contact</Link> {/* react-scrollを使ってスムーススクロール */}
              </li>
            </ul>
	    )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
