import React from 'react';

function Terms() {
  return (
    <div className="container mt-5">
      <div className="p-4">
	<h1>利用規約</h1>
	第1条（適用）<br/>
	この利用規約（以下「本規約」といいます）は、株式会社pixys（以下「当社」といいます）が提供するサービス（以下「本サービス」といいます）に関する条件を定めるものです。本サービスを利用するすべてのお客様（以下「ユーザー」といいます）は、本規約に同意したものとみなされます。<br/>
	<br/>
	第2条（禁止事項）<br/>
	ユーザーは、以下の行為を行ってはなりません。<br/>
	<div className="p-2">
	  法令に違反する行為<br/>
	  公序良俗に反する行為<br/>
	  当社や第三者の権利を侵害する行為<br/>
	</div>
	<br/>
	第3条（利用停止等）<br/>
	当社は、ユーザーが本規約に違反した場合、事前の通知なく本サービスの利用を停止または契約を解除できるものとします。<br/>
	<br/>
	第4条（免責事項）<br/>
	当社は、本サービスの利用に関して発生した損害について、一切の責任を負わないものとします。<br/>
	<br/>
	第5条（規約の変更）<br/>
	当社は、必要に応じて本規約を変更することができます。変更後の規約は、当社ウェブサイトに掲載した時点で効力を生じます。<br/>
	<br/>
	第6条（準拠法・裁判管轄）<br/>
	本規約の解釈および適用に関しては、日本法を準拠法とし、紛争が生じた場合、○○地方裁判所を専属的合意管轄とします。<br/>
      </div>
    </div>
  );
}

export default Terms;
