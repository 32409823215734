import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function ContactForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    setFormData(updatedFormData);

    setIsFormValid(
      updatedFormData.name &&
      updatedFormData.email &&
      updatedFormData.phone &&
      updatedFormData.message
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid) {
      alert('全ての必須項目を入力してください');
      return;
    }

    if (!executeRecaptcha) {
      console.log('reCAPTCHAがまだ利用できません');
      return;
    }

    try {
      // reCAPTCHAトークンを取得
      const recaptchaToken = await executeRecaptcha('contact_form');

      // フォームデータにトークンを追加
      const dataToSubmit = {
        ...formData,
        recaptchaToken,
      };

      const response = await fetch('https://pixys.co.jp/mail/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSubmit),
      });

      const result = await response.json();
      setResponseMessage(result.message); // サーバーからのレスポンスを設定
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`); // エラーをキャッチして表示
    }

    // フォームのクリア
    setFormData({ name: '', email: '', phone: '', message: '' });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="name">お名前</label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="phone">電話番号</label>
        <input
          type="tel"
          className="form-control"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="message">お問い合わせ内容</label>
        <textarea
          className="form-control"
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows="5"
          required
        />
      </div>
      {/* reCAPTCHAコンポーネントは不要 */}
      <button type="submit" className="btn btn-primary mt-3">送信</button>

      {responseMessage && (
        <div id="response" className="mt-3">
          <p>{responseMessage}</p>
        </div>
      )}
    </form>
  );
}

export default ContactForm;
