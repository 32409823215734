import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ContactForm from './ContactForm';

function Home() {
  const items = [
    ["イベント企画提案", "スポーツイベントの趣旨に合わせた企画を提案し、参加者や観客を引きつける魅力的なプランを作成します。イベント成功の基盤を構築します。"],
    ["エントリー方法提案と管理", "参加者が簡単にエントリーできる方法を提案し、オンラインエントリーシステムの構築からデータ管理までをトータルでサポートします。"],
    ["3Dビューの会場地図の作成", "立体的な視点で会場をリアルに確認できる3Dマップを作成、設営をスムースにし、参加者や観客が事前に会場を把握できるようにします。<a href=\"/modelview\">サンプル</a>"],
    ["競技記録方法の提案と計測", "競技に見合った計測システムや記録計測を効率的に行える方法を提案し、大会中のタイム計測や結果の迅速な集計をサポートします。"],
    ["大会パーティ開催", "大会終了後の交流パーティを企画し、参加者同士の交流を促進します。会場手配から運営までトータルサポートします。"],
    ["イベント公式ガイドの作成", "イベントの詳細やスケジュール、参加方法を網羅した公式ガイドを作成し、参加者に必要な情報をわかりやすく提供します。"],
    ["競技説明動画作成", "初めて参加する人にもわかりやすい競技説明動画を作成し、イベント独自のルールや競技の進行方法を映像で正確に伝えます。"],
    ["イベントWEBサイト作成", "イベント情報を公開し、参加者のエントリーやスケジュール確認ができる公式WEBサイトを構築します。タイムラグのない更新を実現します。"],
    ["大型ビジョン貸出と映像管理", "会場に大型ビジョンを設置し、競技の進行状況や重要な情報をリアルタイムで映像配信します。"],
    ["イベント運営サポート", "イベント当日の流れも事前にシミュレートしイベントの運営に必要なスタッフの派遣や当日の運営全般をトータルでサポートします。"],
//    ["イベント時の救護の体制構築", "大会中の緊急事態に備えた救護体制を構築し、参加者の安全を確保するための準備を行います。"],
    ["イベント記録映像の撮影", "大会の重要なシーンやハイライトを記録映像として撮影し、後日のプロモーションやアーカイブに活用します。"]
  ];
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    
    setFormData(updatedFormData);

    setIsFormValid(updatedFormData.name && updatedFormData.email && updatedFormData.message && recaptchaValue);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaValue) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    try {
      const response = await fetch('https://pixys.co.jp/mail/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      setResponseMessage(result.message); // サーバーからのレスポンスを設定
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`); // エラーをキャッチして表示
    }

    // フォームのクリア
    setFormData({ name: '', email: '', message: '' });
  };


  return (
    <div className="container mt-5">
      <div className="parent">
	<div className="pc-only">
	  <img src="images/22138112_m.jpg" width="100%"/>
	</div>
	<div className="sp-only">
	  <img src="images/22138112_m_520.jpg" width="100%"/>
	</div>
	<div className="child-center">
	  <img className="" src="images/logoanime1.png" width="200px"/><br/>
	</div>
	<div className="child-center">
	  <img className="rotate" src="images/logoanime2.png" width="200px"/><br/>
	</div>
	<div className="child-center">
	  <img className="" src="images/logoanime3.png" width="200px"/><br/>
	</div>
      </div>
      <div className="text-center">
	<br/>
	<h1 className="reggae-one-regular">株式会社ピクシス</h1>
	<div className="wid670">
	  <div className="bg9790a4 p-4 fs-1-2 text-left text-sm-center">
	    株式会社ピクシスは<b>沖縄</b>に<b>本社</b>を構える沖縄県内企業として、<div className="pc-only"><br/></div>
	    開催者に<b>やさしく</b>、参加者に<b>うれしい</b>イベントの開催をサポートします<br/>
	  </div>
	</div>
	<div className="p-4"></div>
	<h1 className="reggae-one-regular">沖縄のスポーツイベントを<div className="sp-only"><br/></div>スマートに</h1>
	<div className="wid670 text-center">
	  <div className="p-4 text-left">
	    沖縄でのスポーツイベントをもっと簡単に、便利に、快適に。<b>株式会社ピクシス</b>は、イベントの企画から選手エントリー、記録計測、そしてアフターパーティまで、一貫したサポートを提供します。あなたのスポーツイベントがスムーズに進行するよう、全てのステップでプロフェッショナルなサポートをお約束します。地域密着型のサービスで、沖縄のスポーツ文化を支え、参加者が最大限に楽しめる環境を整えます。初心者からベテランまで、誰もが安心して参加できるスポーツイベント作りを実現します。
	  </div>
	</div>
	<h1 className="reggae-one-regular">本島から離島まで<div className="sp-only"><br/></div>沖縄全域をカバー</h1>
	<div className="wid670 text-center">
	  <div className="p-4 text-left">
	    沖縄本島から離島まで、どこでもスポーツイベントをサポート。株式会社ピクシスは、地域の特性を活かし、全ての島でプロフェッショナルな運営サポートを提供します。企画、エントリー、記録計測、アフターパーティまで一貫してサポートし、参加者全員が快適に楽しめるイベント作りをお手伝いします。離島特有の自然や文化を活かしたイベントで、沖縄の魅力を最大限に引き出し、スポーツを通じて地域社会に貢献します。
	  </div>
	</div>
	<h1 className="reggae-one-regular">ピンポイントでの<div className="sp-only"><br/></div>フォローもお任せ</h1>
	<div className="wid670 text-center">
	  <div className="p-4 text-left">
	    ピンポイントでのフォローもお任せください。あなたが必要な部分だけを的確にサポートします！スポットでのフォローから全体の流れを支えるサポートまで、株式会社ピクシスは柔軟に対応。それぞれの分野に精通した専門スタッフが迅速かつ的確に対応し、最適なソリューションを提供します。必要な時に、必要な手助けを迅速にご提供し、イベントの成功を支えます。
	  </div>
	</div>
	
      </div>
      <div className="p-4"></div>
      
      <div className="container p-4 text-center">
	<div className="wid540 text-left">
	  <h3 className="text-center">株式会社ピクシスの<div className="sp-only"><br/></div>コアミッション</h3>
	</div>
	<div className="p-4"></div>
	<div className="row">
	  <div className="col-12 col-md-4">
	    <div className="box p-2">
	      <h4 className="bg9790a4 reggae-one-regular">企画提案<br/><span className="small">planning</span></h4>
	      <div className="text-left p-2">
		開催者にやさしく、参加者にうれしい。株式会社ピクシスでは、豊富な経験を活かして、イベント成功のための最適なプランを提案します。
	      </div>
	      <img src="images/5083765_m.jpg" width="100%"/>
	      <div className="p-1"></div>
	      <a href="#work1"><button className="btn btn-primary"><i className="fa-solid fa-circle-arrow-right"></i> 詳細はコチラ</button></a>
	    </div>
	  </div>
	  <div className="col-12 col-md-4">
	    <div className="box p-2">
	      <h4 className="bg9790a4 reggae-one-regular">登録補助<br/><span className="small">enyty</span></h4>
	      <div className="text-left p-2">
		登録作業を効率化、株式会社ピクシスはICT技術を活用したエントリーシステムを提案します。SNS連携も含め、主催者と参加者をサポートします。
	      </div>
	      <img src="images/2257294_m.jpg" width="100%"/>
	      <div className="p-1"></div>
	      <a href="#work1"><button className="btn btn-primary"><i className="fa-solid fa-circle-arrow-right"></i> 詳細はコチラ</button></a>
	    </div>
	  </div>
	  <div className="col-12 col-md-4">
	    <div className="box p-2">
	      <h4 className="bg9790a4 reggae-one-regular">記録計測<br/><span className="small">measurement</span></h4>
	      <div className="text-left p-2">
		イベントや予算に応じた計測システムを提案し、スタートからゴールまでの正確な計測を行い、審判と密に連携したスムーズな競技運営をサポートします。
	      </div>
	      <img src="images/1093722_m.jpg" width="100%"/>
	      <div className="p-1"></div>
	      <a href="#work1"><button className="btn btn-primary"><i className="fa-solid fa-circle-arrow-right"></i> 詳細はコチラ</button></a>
	    </div>
	  </div>
	  <div className="p-4"></div>
	</div>
	<div className="wid540 text-left">
	  <h3 className="text-center">株式会社ピクシスの<div className="sp-only"><br/></div>サービス一例</h3>
	</div>
	<div className="p-4"></div>
	<div className="row">
          {items.map((item, index) => (
          <div key={index} className="col-lg-3 col-md-6 col-sm-12">
            <div className="p-2 mb-3 border bg-light text-left">
	      <h5>{item[0]}</h5>
	      <div className="p-1" dangerouslySetInnerHTML={{ __html: item[1] }}></div>
	    </div>
          </div>
          ))}
	  
	</div>

      </div>

      <div className="container p-4">
	<div className="row w-100">
	  <div className="col-lg-6">
	    <div className="position-relative clip-custom">
	      <img src="images/5083765_m_middle.jpg" className="img-fluid" alt="企画提案" />
	    </div>
	  </div>
	  <div className="col-lg-6 d-flex align-items-center"> {/* ここで縦中央に揃える */}
	    <div>
	      <h2><div className="rev">企画提案</div></h2>
	      <p className="lead text-secondary text-underline-custom">
		株式会社ピクシスのプランニング（企画）作業は、長年にわたる様々なスポーツイベントの開催経験を基に、開催者にも参加者にも満足いただけるイベントの提案を行うことが特徴です。
	      </p>
	    </div>
	  </div>
	</div>
      </div>
      <div id="planning" className="container px-4 py-0">
	<h4>イベントに即した企画企画提案</h4>
	<div className="px-4 py-0">
	  株式会社ピクシスでは、各イベントの特性や目的に合わせた柔軟な企画を提供します。例えば、マラソン大会やトライアスロンなどの競技系イベントから、地域の祭りやフェスティバルまで、イベントの種類に応じて最適な企画を提案し、成功に導きます。<br/>
	</div>
	<br/>
	<h4> 豊富な経験に基づく提案</h4>
	<div className="px-4 py-0">
	  これまでに培った多様なイベント開催の実績を活かし、イベントの企画段階から具体的な運営までトータルでサポートします。参加者にとって楽しみやすく、開催者にとっても運営がスムーズになるよう、きめ細かな配慮が行き届いたプランを提供しています。<br/>
	</div>
	<br/>
	<h4>開催者にやさしいサポート</h4>
	<div className="px-4 py-0">
	  株式会社ピクシスは、イベントの主催者が抱える不安や課題を解消するために、丁寧なサポートを行います。企画段階での提案から運営のアドバイス、関係機関に必要な書類の作成から提出まで、運営の負担を軽減し、成功を目指す支援を惜しみません。
	</div>
	<br/>
	<h4>参加者にうれしい体験を</h4>
	<div className="px-4 py-0">
	  参加者にとっては、イベントの質や内容が重要です。参加者がイベントを楽しみ、快適に過ごせるような企画を提案。たとえば、エントリーシステムの使いやすさや、イベント当日の運営のスムーズさなど、参加者にとって嬉しい体験を提供します。<br/>
	</div>
	<br/>
	<div className="bgeae5e3 p-4">
	  株式会社ピクシスの企画作業は、「開催者にやさしく、参加者にうれしい」イベント作りを念頭に置き、すべてのステークホルダーに満足いただけるイベントの実現を目指しています。<br/>
	</div>
      </div>
  
      <div className="p-4"></div>

      <div className="container p-4">
	<div className="row w-100">
	  <div className="col-lg-6">
	    <div className="position-relative clip-custom">
	      <img src="images/2257294_m_middle.jpg" className="img-fluid" alt="登録補助" />
	    </div>
	  </div>
	  <div className="col-lg-6 d-flex align-items-center"> {/* ここで縦中央に揃える */}
	    <div>
	      <h2><div className="rev">登録補助</div></h2>
	      <p className="lead text-secondary text-underline-custom">
		株式会社ピクシスのエントリー（登録）作業は、イベント参加者の登録に関わるすべてのプロセスをスムーズにサポートし、主催者の負担を軽減することを念頭に置いています。
	      </p>
	    </div>
	  </div>
	</div>
      </div>
      <div id="planning" className="container px-4 py-0">
	<h4>大変な登録作業を効率化</h4>
	<div className="px-4 py-0">
	  イベントのエントリー作業は、特に大規模イベントでは煩雑になりがちです。株式会社ピクシスでは、この大変な登録作業を効率的に処理するためのシステムを提供し、主催者がスムーズに参加者情報を管理できるようにします。<br/>
	</div>
	<br/>
	<h4>さまざまなICTを活用</h4>
	<div className="px-4 py-0">
	  最新のICT技術を活用し、イベントの規模や内容に応じた最適なエントリーシステムを提案します。これにより、オンラインでのエントリー受付や支払い処理が簡単に行え、参加者にとっても利便性の高いエクスペリエンスを提供します。<br/>
	</div>
	<br/>
	<h4>イベントに即したエントリーシステム</h4>
	<div className="px-4 py-0">
	  イベントの特性に合わせたカスタマイズが可能なエントリーシステムを提供します。たとえば、スポーツイベント、フェスティバル、地域の催し物など、さまざまなイベントに対応した柔軟な登録ソリューションを提案し、円滑な運営をサポートします。<br/>
	</div>
	<br/>
	<h4>SNSのお手伝い</h4>
	<div className="px-4 py-0">
	  エントリーだけでなく、SNSを活用した広報活動のお手伝いも行います。イベント情報の拡散や参加者の招待を簡単に行えるよう、SNSを使ったプロモーション戦略の提案も行い、より多くの参加者を集める支援を行います。<br/>
	</div>
	<br/>
	<h4>主催者への提案とサポート</h4>
	<div className="px-4 py-0">
	  株式会社ピクシスは、単にシステムを提供するだけでなく、イベントの成功に向けた具体的な提案を行います。エントリーシステムの設計から運用方法まで、主催者の負担を軽減し、イベント運営を円滑に進めるための全面的なサポートを提供します。<br/>
	</div>
	<br/>
	<div className="bgeae5e3 p-4">
	  株式会社ピクシスのエントリー作業は、最新技術を駆使し、主催者と参加者双方にとって効率的かつ快適なエントリー環境を提供します。
	</div>
      </div>
      
      <div className="p-4"></div>

      <div className="container p-4">
	<div className="row w-100">
	  <div className="col-lg-6">
	    <div className="position-relative clip-custom">
	      <img src="images/1093722_m_middle.jpg" className="img-fluid" alt="記録計測" />
	    </div>
	  </div>
	  <div className="col-lg-6 d-flex align-items-center"> {/* ここで縦中央に揃える */}
	    <div>
	      <h2><div className="rev">記録計測</div></h2>
	      <p className="lead text-secondary text-underline-custom">
		株式会社ピクシスの計測（Measurement）作業は、スタートからゴールまでのタイム計測だけでなく、素早く正確な情報の提供を重視し、参加者や主催者に信頼されるサービスを提供します。<br/>
	      </p>
	    </div>
	  </div>
	</div>
      </div>
      <div id="planning" className="container px-4 py-0">
	<h4>スタートからゴールまでのトータルサポート</h4>
	<div className="px-4 py-0">
	  株式会社ピクシスは、スタートからゴールまでの計測を一貫してサポートし、参加者一人ひとりの記録を正確に管理します。レースの途中経過や最終結果まで、すべての計測情報をスムーズに収集し、リアルタイムでの運営をサポートします。<br/>
	</div>
	<br/>
	<h4>さまざまな計測システムの提供</h4>
	<div className="px-4 py-0">
	  株式会社ピクシスは、イベントの種類に合わせて、最先端の計測システムを提供しています。RFIDチップやGPS、モバイルアプリを活用した計測システムにより、どのようなイベントにも対応可能です。参加者のタイムや順位を自動的に収集し、管理者の負担を軽減します。<br/>
	</div>
	<br/>
	<h4>素早い計測情報の提示</h4>
	<div className="px-4 py-0">
	  計測後、素早く結果を提供することが株式会社ピクシスの強みです。リアルタイムでのタイム計測により、イベント中に参加者が自分の順位やタイムを確認できるほか、主催者側にも迅速に結果をフィードバック。これにより、スムーズな大会運営が実現します。<br/>
	</div>
	<br/>
	<h4>イベントに合わせた柔軟なシステム</h4>
	<div className="px-4 py-0">
	  イベントの特性に合わせて計測システムを柔軟にカスタマイズします。マラソンやトライアスロンのような競技系イベントだけでなく、カジュアルなフェスティバルや市民イベントにも最適なシステムを提案します。あらゆる規模やジャンルに応じたサポートが可能です。<br/>
	</div>
	<br/>
	{
	/*
	<h4>審判の派遣サービス</h4>
	<div className="px-4 py-0">
	  株式会社ピクシスは、計測システムの提供に加えて、審判の派遣も行います。正確な計測に加え、公平な競技進行を確保するため、専門の審判員を派遣し、イベント全体のサポートを行います。<br/>
	</div>
	<br/>
	*/
	}
	<h4>主催者への提案とサポート</h4>
	<div className="px-4 py-0">
	  単に計測システムを提供するだけでなく、株式会社ピクシスは主催者に対して最適な計測方法や運営サポートを提案します。主催者が抱える課題を解消し、イベント全体の成功に向けた提案を行い、参加者の満足度向上を目指します。
	</div>
	<br/>
	<div className="bgeae5e3 p-4">
	  株式会社ピクシスの計測作業は、最新の技術を駆使し、素早く正確な計測情報を提供することで、参加者と主催者の期待に応えるサポートを実現しています。
	</div>
      </div>

      <div className="p-4"></div>
      <div id="aboutus" className="container p-4">
	<h1>会社概要</h1>
	<div className="p-2 wid540">
	    <table className="table-center">
	      <tbody>
		<tr>
		  <td>社名</td>
		  <td>株式会社pixys (ピクシス）</td>
		</tr>
		<tr>
		  <td>浦添office</td>
		  <td>〒901-2114 <div className="sp-only"><br/></div>沖縄県浦添市安波茶1-6-5</td>
		</tr>
		<tr>
		  <td>八重山office</td>
		  <td>〒907-0024 <div className="sp-only"><br/></div>沖縄県石垣市新川280-2 1F</td>
		</tr>
		<tr>
		  <td>電話</td>
		  <td>0980-87-0806 <div className="sp-only"><br/></div>(平日:10:00~17:00)</td>
		</tr>
		<tr>
		  <td>メールアドレス</td>
		  <td>info@pixys.co.jp</td>
		</tr>
		<tr>
		  <td>主な取引先</td>
		  <td>
		    ネオシステム株式会社、一般社団法人沖縄県トライアスロン連合、一般社団法人沖縄県ライフセービング協会、株式会社新東通信、石垣市、浦添市、うるま市、他
		  </td>
		</tr>
		<tr>
		  <td>サンプル</td>
		  <td>
		    <a href="/modelview">3Dビューの会場地図サンプル</a>
		  </td>
		</tr>

	      </tbody>
	    </table>
	  
	  
	</div>      
      </div>
      
      <div className="p-4"></div>
      <div id="contact" className="container p-4">
	<h1>お問い合わせ</h1>
	<div className="p-2">
	  <div className="row">
	    <div className="col-md-6 col-12 p-4">
	      <div className="p-2">
		<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
		  <ContactForm />
		</GoogleReCaptchaProvider>
              </div>

	    </div>
	    <div className="col-md-6 col-12 p-4">
	      <h4>ご注意</h4>
	      ☆お客様のメールソフトで受信拒否設定されている場合や、迷惑フォルダへ自動振り分けされる設定になっている場合、当店からの返信が届かない場合がありますので、予め設定をご確認頂きますようお願い致します。迷惑メールの受信/拒否設定を行っている方は、「@pixys.co.jp」からのPCメールを受信できるように設定を行ってください。<br/>
	      ☆メールを送信されてからしばらくしても返信のメールが届かない場合は、お手数ですがメールソフトの受信拒否設定をご確認いただき、再度お問い合わせをしていただきますようよろしくお願いします。<br/>
	    </div>
	  </div>
	</div>
      </div>
    </div>
  );
}

export default Home;
