import React from 'react';

function Policy() {
  return (
    <div className="container mt-5">
      <div className="p-2">
	<h1>プライバシーポリシー</h1>
	株式会社pixys（以下「当社」といいます）は、お客様の個人情報を適切に保護し、以下の方針に基づいて個人情報を取り扱います。<br/>
	<br/>
	1. 個人情報の収集について<br/>
	当社は、お客様からのお問い合わせやサービス提供の際に、必要な範囲で個人情報を収集いたします。収集する情報は、氏名、住所、電話番号、メールアドレスなどです。<br/>
	<br/>
	2. 個人情報の利用目的<br/>
	当社は、収集した個人情報を以下の目的で利用します。<br/>
	<div className="p-2">
	  サービスの提供及びサポート<br/>
	  お問い合わせへの対応<br/>
	  商品・サービスに関するご案内やお知らせ<br/>
	</div>
	<br/>
	3. 第三者への提供<br/>
	当社は、お客様の同意がある場合、または法令に基づく場合を除き、個人情報を第三者に提供することはありません。<br/>
	<br/>
	4. 個人情報の管理<br/>
	当社は、個人情報の漏洩、紛失、改ざんを防止するため、適切な安全管理措置を講じます。<br/>
	<br/>
	5. 個人情報の開示・訂正・削除<br/>
	お客様がご自身の個人情報の開示、訂正、削除を希望される場合、当社所定の手続きにより対応いたします。<br/>
	<br/>
	6. 法令遵守<br/>
	当社は、個人情報保護法その他の関連法令を遵守します。<br/>
	<br/>
	7. お問い合わせ窓口<br/>
	個人情報に関するお問い合わせは、以下までご連絡ください。<br/>
	<div className="p-2">
	  【個人情報に関する問合せ先】<br/>
	  <div className="p-2">
	    株式会社PIXYS<br/>
	    電話番号:0980-87-0806<br/>
	    メールアドレス:info@pixys.co.jp<br/>
	  </div>
	</div>
      </div>
    </div>
  );
}

export default Policy;
